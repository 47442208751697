var lampaCoords,
    lampaMarker,
    lampaInfowindow,
    map,
    directionsDisplay,
    directionsService,
	mapReady = false,
    mapLoaded = false;

function initializeMap() {
    mapLoaded = true;
	if(!$('#map-canvas').length)
		return;
	mapReady = true;
    directionsService = new google.maps.DirectionsService();
        directionsDisplay = new google.maps.DirectionsRenderer({
            markerOptions: { visible: false }
        });
        lampaCoords = new google.maps.LatLng(56.96413, 24.13814);

        var mapOptions = {
            zoom: 13,
            center: lampaCoords,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
        directionsDisplay.setMap(map);

        lampaMarker = new google.maps.Marker({
            position: lampaCoords,
            map: map,
            title: 'Lampa'
        });
        lampaInfowindow = new google.maps.InfoWindow({
            content: '<p><strong>Lampa</strong></p>'
                + "<p><button onclick=\"_gaq.push(['_trackEvent', 'Map', 'Driving directions', 'Bubble']); getDirections();\">" + labels['messages.drive'] + "</button></p>",
            map: map,
            position: lampaCoords
        });
        var stops = [
                [56.96330, 24.13750, 'Tallinas iela',
                        '<p>Trolleybus: 12, 14, 17</p><p>Bus: 1, 3, 6, 14, 16, 21, 40</p>'
                        + '<p>Minibus: 206, 212, 212A, 220, 222, 271</p><p>Night bus: N1</p>'],
                [56.96267, 24.13653, 'Tallinas iela',
                        '<p>Trolleybus: 12, 14, 17</p><p>Bus: 1, 3, 6, 14, 16, 21, 40</p>'
                        + '<p>Minibus: 206, 212, 212A, 220, 222, 271</p><p>Night bus: N1</p>'],
                [56.96360, 24.13612, 'Tallinas iela', '<p>Trolleybus: 5</p>'],
                [56.96305, 24.13586, 'Tallinas iela', '<p>Trolleybus: 25</p><p>Bus: 9</p>'],
                [56.96554, 24.13325, 'Tallinas iela', '<p>Tram: 11</p>'],
                [56.96097, 24.14056, 'Tallinas iela', '<p>Tram: 3, 6</p>']
            ],
            stopMarkers = [],
            stopInfoWindows = [];
        for (var i = 0; i < stops.length; i++) {
            (function(n){
                stopMarkers[n] = new google.maps.Marker({
                    position: new google.maps.LatLng(stops[n][0], stops[n][1]),
                    map: map,
                    title: stops[n][2],
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 3
                    }
                });
                stopInfoWindows[n] = new google.maps.InfoWindow({
                    content: stops[n][3]
                });
                google.maps.event.addListener(stopMarkers[n], 'click', function() {
                    stopInfoWindows[n].open(map, stopMarkers[n]);
                });
            })(i);
        }

        var dirControlDiv = document.createElement('div'),
                dirControl = new DirectionsControl(dirControlDiv, map);
        dirControlDiv.index = 1;
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(dirControlDiv);
}


function handleNoGeolocation() {
    alert(labels['no-geolocation-found']);
}


function DirectionsControl(controlDiv, map) {
  controlDiv.style.padding = '5px';

  // Set CSS for the control border
  var controlUI = document.createElement('div');
  controlUI.style.backgroundColor = 'white';
  controlUI.style.borderStyle = 'solid';
  controlUI.style.borderWidth = '2px';
  controlUI.style.cursor = 'pointer';
  controlUI.style.textAlign = 'center';
  controlUI.title = 'Click to set the map to Home';
  controlDiv.appendChild(controlUI);

  // Set CSS for the control interior
  var controlText = document.createElement('div');
  controlText.style.fontFamily = 'Arial,sans-serif';
  controlText.style.fontSize = '12px';
  controlText.style.paddingLeft = '4px';
  controlText.style.paddingRight = '4px';
  controlText.innerHTML = '<b>' + labels['messages.driving-directions'] + '</b>';
  controlUI.appendChild(controlText);

    google.maps.event.addDomListener(controlUI, 'click', function() {
        _gas.push(['_trackEvent', 'Map', 'Driving directions', 'Top']);
        getDirections();
    });
}

function getDirections() {
    // Try HTML5 geolocation
    if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            var infowindow = new google.maps.InfoWindow({
                map: map,
                position: pos,
                content: '<p>' + labels['messages.you'] + '</p>'
            });
            map.setCenter(pos);

            var request = {
                origin: pos,
                destination: lampaCoords,
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };
            directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    _gas.push(['_trackEvent', 'Map', 'Directions OK']);
                    directionsDisplay.setDirections(response);
                }
            });
        }, function() {
            handleNoGeolocation();
        });
    } else {
        // Browser doesn't support Geolocation
        handleNoGeolocation();
    }
}


function setGallerySize() {
    var h = $(window).height() - 100;
    var w = h * 3 / 2;
    if(w > $(window).width()) {
        w = $(window).width();
        h = w / 3 * 2;
    }
    $('.carousel.page-gallery').height(h);
    $('.carousel.page-gallery').width(w);
}


function openPopup(url) {
    var width = 640, height = 400,
        left = (screen.width - width)/ 2,
        top = (screen.height - height) / 2;
    var loginWindow = window.open(
        "", "_blank",
        "top=" + top + ",left=" + left + ",width=" + width + ",height=" + height
        + ",resizable=1,toolbar=0,scrollbars=1,location=0,status=0,menubars=0"
    );
    var d = '<!DOCTYPE html>'
        + '<html>'
        + '<head><meta http-equiv="content-type" content="text/html; charset=UTF-8" />'
        + '<title>...</title></head>'
        + '<body style="margin:0;padding:0;">'
        + '<div style="width:100%;padding:17px 0;text-align:center;background-color:#fff;color:#000;font:normal 14px/16px sans serif">'
        + '...</div></body></html>';
    if(loginWindow) {
        loginWindow.document.write(d);
        loginWindow.location.href = url;
    }
}


function gallery(links, indexElement, event) {
    return blueimp.Gallery(links, {
        index: indexElement,
        event: event,
        transitionSpeed: 300,
        onslideend: function(i, slide) {
            var id = 'gallery-image-' + i;
            if(history.pushState)
                history.replaceState(null, null, '#' + id);
            else
                window.location.hash = id;
        },
        onclose: function() {
            if(history.pushState)
                history.replaceState(
                    null, null, window.location.pathname + window.location.search
                );
            else
                window.location.hash = '';
        }
    });
}


function showFormMainMessage($form, message, className) {
    if(message != '')
        message = '<p class="' + className + '">' + message + '</p>';
    $form.find('.form-message').html(message);
}


function handleAjaxFormError($form, response) {
    if (response.fields) {
        var prefix = $form.data('prefix');
        $.each(response.fields, function (field, msg) {
            var $input = $form.find('#' + (prefix ? prefix + '\\[' + field + '\\]' : field));
            $input.parents('.form-group').addClass('has-error');
            if(msg.length)
                $input.next('.text-danger').html(msg[0]);
        });
    }

    if (response.messages.length)
        showFormMainMessage($form, response.messages[0], 'bg-danger');
}


function initPage($) {
    $('.carousel').carousel({
        interval: 0
    });
    var $pageGallery = $('#page-gallery');
    if($pageGallery.length) {
        var galleryLinks = document.getElementById('page-gallery').getElementsByTagName('a');
        if (window.location.hash != '' && $(window.location.hash).data('number'))
            gallery(galleryLinks, $(window.location.hash).data('number'), null);
        $pageGallery.click(function (event) {
            event = event || window.event;
            var target = event.target || event.srcElement,
                link = target.src ? target.parentNode : target;
            gallery(galleryLinks, link, event);
        });
    }
    var $pageCarousel = $('.carousel.page-gallery');
    if($pageCarousel.length) {
        //$pageCarousel.carousel('pause');
        if (window.location.hash != '') {
            if ($pageCarousel)
                $pageCarousel.carousel($(window.location.hash).data('number'));
        }
        $pageCarousel.on('slide.bs.carousel', function () {
            $('.carousel.page-gallery').carousel('cycle');
        });
        $pageCarousel.on('slid.bs.carousel', function () {
            var id = $pageCarousel.find('.item.active div').prop('id');
            if (history.pushState)
                history.replaceState(null, null, '#' + id);
            else
                window.location.hash = id;
        });
    }

    var locales = {
        'en': 'en-US',
        'lv': 'lv-LV',
        'ru': 'ru-RU'
    };

    if($("#calendar-canvas").length)
        var calendar = $("#calendar-canvas").calendar({
            tmpl_path: siteRoot + "components/bootstrap-calendar/tmpls/",
            events_source: calendarEvents,
            first_day: 1,
            language: locales[language]
        });

    $('.js-popup').click(function(e){
        e.preventDefault();
        openPopup($(this).attr('href'));
    });

    $('.js-ajax-form').each(function(i){
        // :(
        $(this).find('.form-success').height( $(this).find('.form-container').height() );
    });

    $('.form-success').click(function(e) {
        $(this).slideUp('fast');
        $(this).siblings('.form-container').slideDown('fast');
    });

    $('.js-ajax-form').submit(function(e) {
        e.preventDefault();
        var $form = $(this);

        $form.find('.has-error').removeClass('has-error');
        $form.find('.text-danger').html('');
        showFormMainMessage($form, '', '');

        $.ajax({
            type: "POST",
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function (response) {
                if (response.result == 'success') {
                    var $container = $form.parents('.form-container');
                    var $successContainer = $container.siblings('.form-success');
                    if ($successContainer.length) {
                        $container.slideUp('fast');
                        $successContainer.slideDown('fast');
                    }
                    $form.find('input[type!=hidden], textarea').val('');
                    _gas.push(['_trackEvent', 'Forms', 'Success: ' + $form.attr('action')]);
                } else {
                    handleAjaxFormError($form, response);
                    _gas.push(['_trackEvent', 'Forms', 'Error: ' + $form.attr('action')]);
                }
            },
            error: function(xhr) {
                if(xhr.getResponseHeader('Content-type').indexOf('json') > -1) {
                    var response = $.parseJSON(xhr.responseText);
                    handleAjaxFormError($form, response);
                } else {
                    showFormMainMessage($form, ':(', 'bg-danger');
                }
                _gas.push(['_trackEvent', 'Forms', 'Error: ' + $form.attr('action')]);
            }
        });
    });

	if($('#map-canvas').length && mapLoaded)
		initializeMap();

	if(!window.matchMedia('only screen and (max-width: 480px)').matches)
		$.stellar();
}


;(function($) {
	initPage($);

	var $body = $('html, body');
	var $smoothContent = $('#js-body-container').smoothState({
		blacklist: '.no-smoothstate, a[target="_blank"], a[href*=".pdf"], a[href*=".png"], a[href*=".jpg"]',
		onStart  : {
			duration: 250,
			render  : function (url, $container) {
				mapReady = false;
				$body.addClass('js-loading');
				$body.animate({
					scrollTop: 0
				});
			}
		},
		onEnd : {
			duration: 0,
			render: function (url, $container, $content) {
				$body.css('cursor', 'auto');
				$body.find('a').css('cursor', 'auto');
				$container.html($content);
				$body.removeClass('js-loading');
			}
		},
		callback : function (url, $container, $content) {
			initPage($);
		}
	}).data('smoothState');

    $(window).scroll(function() {
        if ($(document).scrollTop() > 100) {
            $('#navigation-main').removeClass('unshrink').addClass('shrink');
        } else {
            $('#navigation-main').removeClass('shrink').addClass('unshrink');
        }
    });

    setGallerySize();
    $(window).resize(function(){
        setGallerySize();
    });
})(jQuery);